import React from 'react'
import styled from 'styled-components'
import SliderImg from '../images/slider.png'
import PropTypes from 'prop-types'
import CheckBox from './CheckBox.js'
import StyledP from '../styled-components/PStyled'
import {ReactComponent as Locked} from '../images/locked.svg'
import {ReactComponent as Unlocked} from '../images/unlocked.svg'

const StyledLockButton = styled.div`
    margin: auto;
    border-radius: 10px;
    background-color: inherit;
    outline: none;
    height: 20px;
    width: 20px;
    z-index: 5;
    display: flex;
    flex: 1;
    cursor: pointer;

    @media (max-width: 768px) {
        height: 15px;
        width: 15px;      

    }

    @media (max-width: 414px) {
        height: 15px;
        width: 15px;
    }
    `;

const StyledSelect = styled.select`
    outline: none;
    border: none;
    flex: 2;    
    text-align: center;
    width: 50%;
    cursor: pointer;
    background-color: inherit;
    color: ${props => props.theme.textColorReverse};
    font-family: ${props => props.theme.fontStyle};
`

const StyledSelectDiv = styled.div`
    display: flex;
    justify-content: center;
    margin: auto 10px auto 20px;
    width: 60px;
    border-radius: 5px;
    border: 2px solid ${props => props.theme.inputBorderUnselected};
    background-color: ${props => props.theme.checkboxUnselected};
`

const  Sr = (props) => {

    return (
        <div style={{display: 'flex', justifyContent: 'right', margin: `3px auto`}}>
            <StyledP>Players: </StyledP>
            <StyledSelectDiv>
                <StyledSelect value={props.players} onChange={ (e) => {
                    if(!props.playersLock) props.playersLockToggle();
                    props.setPlayers(e.target.value)
                }}>
                    {[1,2,3,4,5,6,7,8,9].map( e => <option key={e} name={e}>{e}</option>)
                    .concat( props.players > 9 ? <option key={props.players} name={props.players}>{props.players}</option>:null ) }
                </StyledSelect>
                <StyledLockButton onClick={() => props.playersLockToggle() }>
                    {props.playersLock ? <Locked fill="white" /> : <Unlocked onClick={() => props.playersLockToggle() } />}
                </StyledLockButton>                
            </StyledSelectDiv>

            <img src={SliderImg} onClick={() => props.sliderToggle()} style={{width: '40px', height: '22px', margin: 'auto 0'}} />
            <CheckBox checked={props.showSlider} onChange={ () => props.sliderToggle()} />
        </div>
    )
}

export default Sr;

Sr.propTypes = {
    sliderToggle: PropTypes.func,
    showSlider: PropTypes.bool
}

