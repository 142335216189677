import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import idcApi from './lib/idcApi';

const ProductsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    color: ${props => props.theme.textColor};
    font-family: ${props => props.theme.fontStyle};
    min-height: 100vh;

    div {
        display: flex;
        flex-direction: row;
        margin: 5px;

        img {
            width: 100%;
        }

        div {
            display: flex;
            flex-direction: column;
            justify-content: left;
            text-align: left;
        }
        p {
            display: block;
            margin: 5px;

            a {
                color: ${props => props.theme.inputHighlightText};
            }
        }
    }

    a {
        color: ${props => props.theme.inputHighlight};
    }
`
const defaultHTML = `<div>
<div><a href="https://www.amazon.com/DONT-CHOP-raise-Pullover-Hoodie/dp/B08NDW2J11?qid=1649204301&refinements=p_4%3AI+Don%27t+Chop+Poker+Products&s=apparel&sr=1-2&linkCode=li3&tag=idc256-20&linkId=004349f408d30a0289ee8fc674d8b74a&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08NDW2J11&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=idc256-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=idc256-20&language=en_US&l=li3&o=1&a=B08NDW2J11" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" /></div>
<div>
    <p><a href="https://www.amazon.com/DONT-CHOP-raise-Pullover-Hoodie/dp/B08NDW2J11?qid=1649204301&refinements=p_4%3AI+Don%27t+Chop+Poker+Products&s=apparel&sr=1-2&linkCode=ll1&tag=idc256-20&linkId=2ef5899f5fcc696046a9b6eecb90d265&language=en_US&ref_=as_li_ss_tl">I DON'T CHOP</a> Poker Hoodie</p>
    <p>Support I Don't Chop with a Hoodie Purchase!</p>
</div>
</div>
<div>
<div><a href="https://www.amazon.com/DONT-CHOP-raise-T-Shirt/dp/B08MH2XVHW?qid=1649204301&refinements=p_4%3AI%2BDon%27t%2BChop%2BPoker%2BProducts&s=apparel&sr=1-3&customId=B07537PBB4&th=1&linkCode=li3&tag=idc256-20&linkId=deea6a83f1375b70ffc4d89c0d3f2809&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08MH2XVHW&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=idc256-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=idc256-20&language=en_US&l=li3&o=1&a=B08MH2XVHW" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" /></div>
<div>
    <p><a href="https://www.amazon.com/DONT-CHOP-raise-T-Shirt/dp/B08MH2XVHW?qid=1649204301&refinements=p_4%3AI%2BDon%27t%2BChop%2BPoker%2BProducts&s=apparel&sr=1-3&customId=B07537PBB4&th=1&linkCode=ll1&tag=idc256-20&linkId=56d42a0656c2424fdaa86d975026b67b&language=en_US&ref_=as_li_ss_tl">Or T Shirt</a></p>
    <p></p>
</div>
</div>`
const P = (props) => {

    const [innerHtml, setInnerHtml] = useState("")

    useEffect( () => {

        idcApi.get('products','text')
            .then(d => {
                setInnerHtml(d)
            })
            .catch( e => {
                // unable to pull, use default
                setInnerHtml(defaultHTML)
            })

    },[])

    return <ProductsWrapper dangerouslySetInnerHTML={{__html: innerHtml}}></ProductsWrapper>

}

export default P;