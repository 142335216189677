import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import hs from 'holdem-strings'
import HandButton from './HandButton';
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider';
import { SliderRail, Handle, Track, Tick } from './SliderComponent';
import PropTypes from 'prop-types';
import KeyboardPng from '../images/keyboard.png'
import { MobileView } from 'react-device-detect';

const KeyBoardWrapper = styled.div`
    margin: 16px auto;
    width: 96%;
    max-width: 600px;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: 'Courier';
`;

const QuickSelectDiv = styled.div`
    margin: 10px auto;
    width: 96%;
    display: flex;
    flex-flow: row;
    align-items: stretch;
    `;

const QuickSelectButton = styled.button`
    margin: 0 15px;
    padding: 5px;
    border-radius: 5px;
    flex-grow: 1;
    outline: none;
    background-color: ${props => props.active ? `#85C43D` : '#fcfcca'};

    @media (max-width: 768px) {
        margin: 0 5px;
    }
    
    @media (max-width: 414px) {
        margin: 0 2px;
    }
    `;

const HandDiv = styled.div`
    width: 100%;
    padding: 1px;
    display: flex;
    `;

const PercentField = styled.input`
    margin: 2px 15px;
    padding: 2px;
    font-family: 'Courier';
    width: 40px;
    text-align: right;
    `;

const ALLPAIRS = hs.expandArray(hs.stringToArray("22+")[0])
const LPRAISE = hs.expandArray(hs.stringToArray("22+,A2s+,A2o+,KTo+,K2s+,QTo+,Q8s+,J9o+,J8s+,T8s+,97s+,T9o,87s,76s,65s")[0])
const EPRAISE = hs.expandArray(hs.stringToArray("88+,AJo+,ATs+,KJs+,KQo,QJs")[0])

const Hka = (props) => {


    // Raw hand string array, this will be passed back up to the individual hand field in a filtered state
    const [handString, setHandString] = useState([]);
    const [handBar, setHandBar] = useState(1);
    const [handBarPos, setHandBarPos] = useState(1);

    const [percentFieldFocused, setPercentFocused] = useState(false)
    const [percentValue, setPercentValue] = useState(1)

    /**
     * Component did mount
     */
    useEffect(() => {

        // @todo single card limitations
        // Works for ranges. May want to modify holdemstrings to support single cards
        // this affects boardkeyboard, workaround for now is using props.hand directly
        if (!!props.hand) {
            console.log("props.hand -", props.hand)
            setHandString(hs.expandArray(hs.stringToArray(props.hand)[0]));

        }        

    }, [])

    /**
     * Called by downstream button components. Adds or subtracts hands
     * from hand string.
     * Call examples:
     * 
     * +AA
     * -AKo
     * +AcAh
     * -2c9s
     * 
     * @param {String} handUpdate 
     */
    const updateHand = (handUpdate) => {

        setHandString( prevString => {
            if (!prevString) {
                prevString = [];
            }
            if (handUpdate.startsWith("+")) {
                if (!prevString.includes(handUpdate.substring(1))) {
                    return prevString.concat([handUpdate.substring(1)])
                }
            } else if (handUpdate.startsWith("-")) {
                // remove from
                //console.log(prevString)
                return prevString.filter( e => e !== handUpdate.substring(1))
            } else {
                // should always start with + or -
                console.log("Error: handUpdate received " + handUpdate)
            }

            return [...prevString]
        })
    }

    useEffect ( (s) => {
        
        updateHandTextField()             

    }, [handString])

    const rawUpdateHand = (handUpdate) => {

        setHandBarPos(1)

        // if already includes all, it will remove
        if ( handUpdate.every( v => handString.includes(v)) ) {
            
            setHandString( prevString => {
                return prevString.filter ( e => !handUpdate.includes(e))
            })

        } else {

            setHandString( prevString => {
                
                if (!prevString) {
                    prevString = []
                }

                return prevString.concat(handUpdate)
            })
        }
    }

    /**
     * Called when handString state is updated in useEffect hook, or when the slider
     * bar is dropped. Cannot call when the slider is moved for performance reasons.
     */
    const updateHandTextField = () => {

        if ( props.handRanges !== null ) {
            let keyboardString = []
            props.handRanges.map ( row => 
                row.map( cell => cell).filter(e => e.weight > (170 - handBarPos))
                .forEach( e => keyboardString.push(e.hand))
                )
            console.log(handString)
            props.update(handString
                .concat(keyboardString))
            }
    }

    const resetHandStringFromKeyboard = (v) => {

        let keyboardString = []
        props.handRanges.map ( row => 
            row.map( cell => cell).filter(e => e.weight > (170 - v))
            .forEach( e => keyboardString.push(e.hand))
            )
        
        setHandString( prev => prev.concat(keyboardString))
    }
    
    const updateHandBar = ( [v] ) => {
        setHandBar(v);
        if (handString.length > 0) {
            setHandString([])
        }
    }

    const updateHandBarPos = ( [v] ) => {
        setHandBarPos(v)         
        resetHandStringFromKeyboard(v)
        setHandBar(1)
    }

    const getPercentage = () => {

        return (props.handRanges
            .reduce ( (a,c) => a+c.reduce ( (ac,d) => ac + handString.includes(d.hand), 0 ), 0)
            / 169 * 100).toFixed(1)
    }

    const handsByPercentage = (percent) => {

        console.log((percent / 100) * 169)

        let a = []

        props.handRanges.forEach ( row => {
            row.forEach ( cell => {
                if ( cell.weight >= 170 - ((percent / 100) * 169)) {
                    a.push(cell.hand)
                }
            })
        })

        return a
    }

    const handlePercentManual = (v) => {

        setPercentValue(v)

    }

    const handleSetPercent = (v) => {

        setPercentFocused(false)

        setHandString( handsByPercentage(v) )
    }

    // single card
    //console.log('hand keyboard props: ', props)
    if ( props.handRanges === null ) {
        return (
            <>
                <KeyBoardWrapper>
                    {['s','h','d','c'].map ( suit => {
                        return <HandDiv key={suit}>
                        {['2','3','4','5','6','7','8','9','T','J','Q','K','A'].map ( rank => {
                            return <HandButton 
                                key={`${rank}${suit}`}
                                hand={`${rank}${suit}`}
                                selected={props.hand.includes(`${rank}${suit}`)}
                                updateHand={props.update} />
                                    
                        })}
                        </HandDiv>
                    })

                    }
                </KeyBoardWrapper>
            </>
        )
    // Ranges
    } else return (
        <>
        <KeyBoardWrapper>
        <QuickSelectDiv>
            <MobileView> 
                <QuickSelectButton 
                onClick={ () => props.updateToggleKeyboard() }>
                <img src={KeyboardPng} style={ {width: "40px", height: "20px"} } />
                </QuickSelectButton>
            </MobileView>
            <QuickSelectButton
                active= {ALLPAIRS.every( v => handString.includes(v))}
                onClick={ () => rawUpdateHand(ALLPAIRS)}>
                Pairs
            </QuickSelectButton>
            <QuickSelectButton 
                active= {EPRAISE.every( v => handString.includes(v))}
                onClick={ () => rawUpdateHand(EPRAISE)}>
                EP Raise
            </QuickSelectButton>
            <QuickSelectButton 
                active= {LPRAISE.every( v => handString.includes(v))}
                onClick={ () => rawUpdateHand(LPRAISE)}>
                LP Raise
            </QuickSelectButton>

            <PercentField type={"text"} value={percentFieldFocused ? percentValue : getPercentage() }
                onFocus={ (e) => { 
                    setPercentFocused(true)
                    e.target.select()} }
                onBlur={ (e) => handleSetPercent(e.target.value) }
                onChange={ (e) => handlePercentManual(e.target.value)} />
            
            { percentFieldFocused ?
            <QuickSelectButton
                active={true}
                onClick={ e => e.preventDefault()}>
                {percentFieldFocused ? `>` : 'X'}                
            </QuickSelectButton> :
            <QuickSelectButton
            active={false}
            onMouseDown={ (e) => {
                e.preventDefault()
                if(!percentFieldFocused) {
                    setHandString([])
                }
            }} >
            {percentFieldFocused ? `>` : 'X'}                
        </QuickSelectButton> }            
        </QuickSelectDiv>
        <div style={{ margin: "15px auto 0px auto", height: 20, width: "70%" }}>
            <Slider domain={[1,169]} values={[handBarPos]} mode={2} step={1} 
            rootStyle={{position: "relative", width: "100%"}}
            onUpdate={ (v) => updateHandBar(v)}
            onChange={ (v) => updateHandBarPos(v)}>
            
            <Rail>
                {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
            </Rail>
            <Handles>
                {({ handles, getHandleProps }) => (
                <div className="slider-handles">
                    {handles.map(handle => (
                    <Handle
                        key={handle.id}
                        handle={handle}
                        domain={[1,169]}
                        getHandleProps={getHandleProps}
                    />
                    ))}
                </div>
                )}
            </Handles>
            <Tracks right={false}>
                {({ tracks, getTrackProps }) => (
                <div className="slider-tracks">
                    {tracks.map(({ id, source, target }) => (
                    <Track
                        key={id}
                        source={source}
                        target={target}
                        getTrackProps={getTrackProps}
                    />
                    ))}
                </div>
                )}
            </Tracks>
            </Slider>
            
        </div>
  
            { props.handRanges.map ( row =>  <HandDiv key={row[0].hand} >
                { row.map( cell => 
                <HandButton {...cell} 
                    key={cell.hand}
                    selected={handString.includes(cell.hand) || cell.weight > (170 - handBar)}
                    updateHand={(s) => updateHand(s)} />
                )}
                </HandDiv>
            )}

        </KeyBoardWrapper>
        </>
    )
}

Hka.propTypes = {
    handRanges: PropTypes.array,
    hand: PropTypes.string,
    update: PropTypes.func,
    updateToggleKeyboard: PropTypes.func,
    toggleKeyboard: PropTypes.bool
}


export default Hka;