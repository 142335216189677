import React, { createContext, useState, useEffect } from 'react';
import { Fragment } from 'react';
import styled from 'styled-components';
import idcApi from '../lib/idcApi';
import PStyled from '../styled-components/PStyled'

const ProfileDiv = styled.div`
  width: 100%;
  margin: 0;
  display: flex;
  padding-left: 0;
  `;

const SplitDiv = styled.div`
  flex: ${props => props.flex ? `${props.flex}` : `1`};
  padding: 2px 10px;
  margin: 0;
`;

const StyledProfileImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-left: 5px;
  `;


const Tp = (props) => {

  const [profileImage, setProfileImage] = useState({})

  /**
   * Component Did Mount
   */
  useEffect ( () => {

    // Load Profile pic (dev token set)
    idcApi.getPic('profilePic')
    .then( (response) => {
      let image = URL.createObjectURL(response)
      setProfileImage(image)
    })
    .catch ( e => {
      // default profile image
      setProfileImage('guestprofile.jpg')
    });

  }, [])



    return (
        <ProfileDiv>
            <SplitDiv><StyledProfileImage src={profileImage} /></SplitDiv>
            <SplitDiv flex={"3"}>
              <PStyled style={{textAlign: "left"}}>
                Welcome
              </PStyled>
              <PStyled>{props.user.name.split(" ")[0]}!</PStyled>
            </SplitDiv>
        </ProfileDiv>
    )
}

export default Tp;