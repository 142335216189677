import React, {useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const StyledBar = styled.div`
    display: block;
    width: 100%;
    background: rgb(94,61,34);
    background: linear-gradient(0deg, rgba(94,61,34,0.053658963585434205) 0%, rgba(150,128,110,0.2721463585434174) 25%, rgba(150,128,110,0.28335084033613445) 50%, rgba(150,128,110,0.16290266106442575) 75%, rgba(94,61,34,0.04525560224089631) 100%);
    border-radius: ${ props => props.collapsed ? `10px` : `10px 10px 0px 0px` };
    text-align: center;
    font-family: ${props => props.theme.fancyFontStyle};
    color: ${props => props.theme.h1TextColor};
    padding: ${ props => props.collapsed ? `15px 0` : `5px 0` };
    position: relative;
    transition: all 1s;
    margin: 5px 0 0 0;



  `;

const StyledArrow = styled.div`
    width: 0; 
    height: 0; 
    
    ${ props => props.collapsed ? 
    `border-top: 12px solid transparent;
    border-bottom: 12px solid transparent; 
    border-right:12px solid #686c73; ` :
    `  border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    
    border-top: 10px solid #686c73;` }
    display: inline-block;

  `;

const StyledTitle = styled.h1`
  display: inline-block;
  margin: ${ props => props.collapsed ? `1em 1.5em` : `0.4em 2em` };
  font-family: ${props => props.theme.fancyFontStyle};
  font-size: ${ props => props.collapsed ? `2.3em` : `1.6em` };
  color: ${ props => props.theme.h1TextColor};
  transition: all 1s;
  padding: 0;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none;

  
  @media (max-width: 768px) {
    font-size: ${ props => props.collapsed ? `1.6em` : `1.4em` };
    margin: ${ props => props.collapsed ? `1em 1em` : `0.4em 2em` };
  }

  @media (max-width: 450px) {
    font-size: ${ props => props.collapsed ? `1.2em` : `0.9em` };
    margin: ${ props => props.collapsed ? `1em 1em` : `0.4em 1em` };
  }


  `;

const CB = (props) => {

    const [collapsed, setCollapsed] = useState(true);

    /**
     * When opened, scroll to top of window
     */

     let barRef = useRef()

    useEffect ( () => {
      if (!collapsed) {
        window.scrollTo( {behavior: 'smooth', top: barRef.current.offsetTop})
    
      }
    },[collapsed])

    return (
        <>
            <StyledBar ref={barRef}
              collapsed={collapsed}
              onClick={ () => {

                setCollapsed(!collapsed)
              } }>
                <StyledTitle collapsed={collapsed}>{props.title}</StyledTitle>
                <StyledArrow collapsed={collapsed} />
            </StyledBar>
            {!collapsed && props.children}
        </>
    )
}

export default CB;