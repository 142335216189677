import React, { createContext, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import {ReactComponent as Locked} from '../images/locked.svg'
import {ReactComponent as Unlocked} from '../images/unlocked.svg'

const GroupWrapper = styled.div`
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
  `;

const SelectButton = styled.button`

    margin: 5px auto;
    display: block;
    width: 50%;
    height: 80%;
    border-radius: 15px;
    border: 3px solid ${props => props.theme.inputBorder};
    padding: 8px 15px;
    background-color: ${props => props.theme.inputBackgroundUnselected};
    
    outline: none;

    color: #707070;

    font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
    font-size: 1.1em;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    line-height: 20px;
    -webkit-text-shadow: 3px 3px 4px -1px rgba(0,0,0,0.77); 
    text-shadow: 1px 1px rgba(0,0,0,0.1);

  `;

const Apb = (props) => {

    const [buttonMessage, setButtonMessage] = useState("+")

    useEffect( () => {
        if(props.playersLock) {
            setButtonMessage(<Locked style={{width: "20px", height: "20px"}}/>)
        } else {
            setButtonMessage("+")
        }
    }, [props])
    return (
        <GroupWrapper>
            <SelectButton onClick={() => props.onclick()}>
                {buttonMessage}
            </SelectButton>
        </GroupWrapper>
    )
}

Apb.propTypes = {
    onclick: PropTypes.func.isRequired
}


export default Apb;