import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types';

const SM = styled.div`
  display: block;
  position: absolute;
  top: ${props => !!props.top ? props.top : `15%`};
  bottom: ${props => !!props.bottom ? props.bottom : `0`};
  left: ${props => !!props.left ? props.left : `25%`};
  right: ${props => !!props.right ? props.right : `0`};
  width: ${props => !!props.width ? props.width : `250px`};
  height: ${props => !!props.height ? props.height : `250px`};
  z-index: 99;
  padding: 10px 10px 12px 12px;
  border-radius: 5px;
  border: 3px solid #707070;
  background: #f2efda;
  box-shadow: 0px 0px 8px 6px rgba(0,0,0,0.55);
  ${props => !!props.dangerousCSS ? props.dangerousCSS : ``}
  `;
//  -webkit-box-shadow: 2px 2px 2px 0px #000000, inset 2px 2px 12px 0px #000000, 2px 2px 12px 2px rgba(112,112,112,0); 
//  box-shadow: 2px 2px 2px 0px #000000, inset 2px 2px 12px 0px #000000, 2px 2px 12px 2px rgba(112,112,112,0);

export const StyledH2 = styled.h1`
  font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
  font-size: 1.1em;
  `;

export const StyledP = styled.p`
font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
font-size: 0.9em;
text-align: left;
  `;

export default SM;
