import React from 'react'
import StyledModal, {StyledH2, StyledP} from '../styled/modal'

export default function one (e) {

    const dems = {
        height: "300px"
    }
    return <>
    <StyledModal {...dems}>
        <StyledH2>Beta Notes:</StyledH2>
        <StyledP>Lock / Unlock is not fully developed, especially for Prize Pool.</StyledP>
        <StyledP>
            Locking the Prize Pool is likely to result in browser lockup currently. Usage of entering prize pool while unlocked is meant to reset all fields. Modifying fields will not currently update Prize Pool.
        </StyledP>

        {e}
    </StyledModal>
    
    </>

}