import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const StyledHandButton = styled.button`
  width: 100%;
  border-radius: 6px;
  font-family: "Courier Prime", courier;
  font-size: 1.3em;
  color: black;
  overflow: hidden;
  padding: 1px 0;
  background-color: ${props => determineColor(props.hand, props.selected) };

  &:focus {
      outline: none;
  }

  @media (max-width: 768px) {
    font-size: 1.1em;
    padding: 4px 0px;
    margin: 1px;
  }


  @media (max-width: 450px) {
    font-size: 0.8em;
    padding: 1px 0;
    margin: 0;
  }

  @media (max-width: 420px) {
    font-size: 0.7em;
    padding: 1px 0;
    margin: 0;
  }

`

const determineColor = (cardString, selected) => {

    let singleCardSuitColors = {
        's': `87,87,87`,
        'h': `207,117,72`,
        'd': `15, 173, 252`,
        'c': `62,181,70`
    }

    let color = "207,117,72";
    if ( cardString.length === 2 && (['s','h','d','c'].includes(cardString[1]))) {
        // single card
        color = singleCardSuitColors[cardString[1]]


    } else {
        // range

        if (cardString.endsWith("o")) {
            color = `207,117,72`;
        } else if ( cardString.endsWith("s")) {
            color = `51,145,70`;
        } else {
            color = `51,145,145`;
        }

    }
    
    return selected ? `rgba(${color}, 1.0)` : `rgba(${color}, 0.4)`
}

const Hb = (props) => {

    const updateHand = () => {
        if ( !props.selected ) {
            props.updateHand(`+${props.hand}`)
        } else {
            props.updateHand(`-${props.hand}`)
        }
    }

    //console.log(props)
    return (
        <StyledHandButton hand={props.hand} selected={props.selected} onClick={()  => updateHand()}>
            {props.hand}
        </StyledHandButton>
    )

}

export default Hb;