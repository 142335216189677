import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Spinner } from './Spinner'

const StyledButton = styled.button`
  margin: 1px;
  padding: 10px;
  width: 100%;
  background-color: #ffeeb3;
  font-family: 'Roboto', sans-serif;
  color: #707070;
  font-style: oblique;

  &:focus {
      outline: none;
  }

  &:active {
    border: 2px solid grey;
    color: grey;
  }
  `;
const StyledWorking = styled.div`
    background-color: #ffeeb3;
    border-radius: 10px;
    position: relative;
    display: block;
    width: 100%;
    min-height: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
`


const GoButton = (props) => {

    return props.awaitingResult ? <StyledWorking><Spinner /></StyledWorking> : <StyledButton onClick={() => props.onClick()}>Go!</StyledButton>
            
    
}

GoButton.propTypes = {
    onClick: PropTypes.func.isRequired
}

export default GoButton;