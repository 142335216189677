import React from 'react'
import StyledModal, {StyledH2, StyledP} from '../styled/modal'
import styled from 'styled-components'
import android from '../../images/tooltips/android.png'
import desktop from '../../images/tooltips/desktop.png'
import linux from '../../images/tooltips/linux.png'
import { osName } from 'react-device-detect';

const LeftImg = styled.img`
    margin: auto 15px auto 0;
    border-radius: 10px;
    background-color: inherit;
    outline: none;
    height: auto;
    width: 40%;
    z-index: 5;
    float: left;

    @media (max-width: 768px) {
     

    }

    @media (max-width: 414px) {

    }
    `;

    /**
     * Returns proper elements based on user OS.
     * 
     * Simple for now, seperating windows, android, ios. Later, can use this method for a more robust class that
     * seperates all combos
     * @returns Obj
     */
const DeviceSpecific = () => {

    process.env.NODE_ENV === 'development' && console.log(osName)

    return "Windows" === osName ?        
        <StyledP>
            <LeftImg src={desktop} /> <b>Desktop: </b> Add to home screen, look in location bar!
        </StyledP> :
        "Android" === osName ?
        <StyledP>
            <LeftImg src={android} /> <b>Android: </b> Add to home screen, look below!
        </StyledP> :
        "iOS" === osName ?
        <StyledP>
            <b>Apple: </b> Add to home screen, (under construction)!
        </StyledP> :
        "Linux" === osName ?
        <StyledP>
            <LeftImg src={linux} /> <b>Linux: </b> <p>Add to home screen, 3-dots -{'>'} More Tools -{'>'} Create Shortcut!</p>
        </StyledP> :
        <StyledP>
            <b>Unknown device: </b> Try bookmarking!
        </StyledP>

}

export default function one (e) {

    const dems = {
        height: '295px',
        width: '80%',
        left: '50%',
        top: '5%',
        dangerousCSS: 'transform: translate(-50%);'
    }
    return <>
    <StyledModal {...dems}>
        <StyledH2>Welcome to IDC Poker (Beta)!</StyledH2>
        <StyledP>IDC Poker is a Progressive Web App, designed for the web, works like an APP.</StyledP>
        <StyledP><b>Beta:</b> The developers are currently adding features. While tested in house before making public, please use at own risk.</StyledP>
        {DeviceSpecific()}
        {e}
    </StyledModal>
    
    </>

}