import React, { createContext, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

export const StyledDeletePlayerButton = styled.button`
    position: absolute;
    right: 0; top: 0;
    height: 100%;
    margin: 0;
    border-radius: 0 4px 4px 0;
    border: 1px solid ${props => props.theme.clearButtonBackground};
    border-left: 2px solid ${props => props.theme.inputBorder};
    background-color: ${props => props.theme.clearButtonBackground};
    color: ${props => props.theme.textColorReverse};
    z-index: 2;
    float: right;

    &:after {
        content: "X";
      }
`
export const StyledFieldWrapper = styled.div`
    position: relative;
    border: 2px solid ${props => props.isFocused ? props.theme.inputBorderSelected : props.theme.inputBorderUnselected};
    border-radius: 5px;
    background-color: ${props => props.isFocused ? props.theme.inputBackgroundSelected : props.theme.inputBackgroundUnselected};
    outline-color: ${props => props.theme.inputBorderSelected};
    margin: 2px 10px;
    display: flex;
    flex-wrap: no-wrap;
    

    @media (max-width: 768px) {
        font-size: 0.9em;
        margin: 2px 5px;
    }

    @media (max-width: 450px) {
        

        img {
            max-width: 25px;
    
        }
    }


`

const StyledField = styled.input`
    border: none;
    background-color: inherit;
    outline: none;
    padding: 0.7em 0.5em;
    margin: 0;    

    &::selection {
        background: ${props => props.theme.inputHighlight};
        color: ${props => props.theme.inputHighlightText};
    }

    &::-moz-selection {
        background: ${props => props.theme.inputHighlight};
        color: ${props => props.theme.inputHighlightText};
    }

    color: ${props => props.theme.textColor};

    font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
    font-size: 1.2em;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    line-height: 0px;
    -webkit-text-shadow: 3px 3px 4px -1px rgba(0,0,0,0.77); 
    text-shadow: 1px 1px rgba(0,0,0,0.1);

    @media (max-width: 768px) {
        font-size: 0.9em;
        padding: 0.7em 0.2em;
        margin: 0;        
        width: 120px;
    }

    @media (max-width: 450px) {
        width: 90px;
    }

`;

export default StyledField;