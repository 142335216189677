import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types'

const Modal = styled.div`
    position: absolute;
    display: block;
    z-index: ${props => props.hasMessage ? `2000` : `-2000`};
    right: 0;
    bottom: 0;
    margin: auto;
    max-height: 150px;
    max-width: 85%;
    border-radius: 5%;
    margin: 0px 5% 5% 0px;
    overflow: auto;
    transition: all ease 1s;
    opacity: ${props => props.hasMessage ? `1.0` : `0.0`};
`;

const Inner = styled.div`
    margin: 5px;
    padding: 19px;
    background: #d6d6d6;
    border-radius: 5px;
    font-size: 1.2em;
    font-family: ${props => props.theme.fontStyle};
    -webkit-box-shadow: 0px 0px 18px 5px rgba(255,255,255,0.82); 
    box-shadow: 0px 0px 18px 5px rgba(255,255,255,0.82);
    transition: all ease 1s;

    @media (max-width: 768px) {
        font-size: 5vw;
    }
`;

const XButton = styled.button`
    position: absolute;
    right: 0;
    top: 0;
    color: gray;
    outline: none;
    border: none;
    &:after {content: "✘";}
`;

const Mm = (props) => {

    const DELAYTIME = 5000;  // time in ms modal stays visible

    const [hasMessage, setHasMessage] = useState(false)
    const [userSetOff, setUserSetOff] = useState(false)
    const [messageVersion, setMessageVersion] = useState(0)

    /**
     * Received new props
     */
    useEffect( () => {

        if (props.message && props.messageVersion !== messageVersion) {
            setHasMessage(true)
            setMessageVersion(props.messageVersion)
            setUserSetOff(false)

            setTimeout ( () => setHasMessage(false), DELAYTIME)
    
        }
        
    }, [props])

    return <>{!userSetOff && <Modal hasMessage={hasMessage}>
        <XButton onClick={ () => setUserSetOff(true) } /><Inner>
        {props.message}
        </Inner></Modal>}</>

}

export default Mm;

Mm.propTypes = {
    message: PropTypes.string
}