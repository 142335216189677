
const axios = require('axios').default;

const portfolioUrl = process.env.NODE_ENV === "development" ?
    "https://idontchop.com/portfolio-war" : "https://idontchop.com/portfolio-war";

const idcApiUrl = process.env.NODE_ENV === "development" ?
    "https://idontchop.com/portfolio-war" : "https://idontchop.com/portfolio-war";

const idcHome = process.env.NODE_ENV === "development" ? 
    "http://localhost:3000" : "https://idontchop.com";

// argument passed to know which url to use
const idcUrl = {

    // portfolio endpoints
    profilePic: portfolioUrl + `/me/image`,
    profile: portfolioUrl + `/me/profile`,
    user: `/user`,
    formLogin: `/login`,
    newFormUser: `/newFormUser`,
    guestBook: `/guestBook`,
    uploadImage: `/uploadImage`,
    publish: `/me/publish`,
    errorReport: idcApiUrl +  `/error-report`,

    // hand equity endpoints
    handEquity: idcApiUrl + `/equity`,

    // hit
    hit: idcApiUrl + `/hit`,

    // Products endpoints
    products: `https://beta.idontchop.com/products.html`
}

/**
 * Must be used for urls when accessing api.
 * 
 * @param {string} type Must match a key in idcUrl
 * @param {*} pathParam optional, added with '/' to url
 */
const buildUrl = (type, pathParam) => {
    if (type in idcUrl) {
        if (!!pathParam) return idcUrl[type] + '/' + pathParam;
        else return idcUrl[type];
    } else {
        throw new Error('Got url type not in authorized list.')
    }
}

const buildHeaders = () => {

    let bheaders = {}
    let params = new URLSearchParams(window.location.search);

    // token in url always higher priority
    if ( params.has("t")) {
        bheaders['Authorization'] = 'Bearer ' + params.get("t");
    }
    else if (window.localStorage.hasOwnProperty('token')) {
        bheaders['Authorization'] = 'Bearer ' + window.localStorage.getItem("token");
    }

    return bheaders

}

/**
 * Accepts a response object and runs checks that the fetch successed.
 * Returns boolean true / false
 */
const checkResponse =  (response) => {

    if ( response && response.status === 200 )
        return true;
    else return false;
}

/**
 * Maybe this is really easy with Axios
 */
const getPayload = async (response, type) => {

    if ( type === 'json') {

        return response.data;

    } else if ( type === 'blob' ) {
        
        return response.data;
        
    } else if ( type === 'text' ) {

        return response.data
        
    } else {
        throw new Error("Invalid Parameter: " + type)
    }

}

const idcApi = {

    setToken: (token) => {
        window.localStorage.setItem('token', token)
    },

    logout: () => {
        window.localStorage.removeItem('token')
        window.history.replaceState({}, document.title, )
    },

    getJson: async (type, pathParam) => {
        try {
            return await idcApi.get(type, 'json', pathParam)
        } catch (e) {
            throw e;
        }
    },

    getPic: async (type, pathParam) => {
        try {
            return await idcApi.get(type, 'blob', pathParam)
        } catch (e) {
            throw e;
        }
    },

    get: async (type, responseType, pathParam) => {

        if (!type || !responseType) {
            throw new Error("get parameters incomplete.")
        }

        try {
            let url = buildUrl(type, pathParam)

            let response = await axios.get(url, {headers: buildHeaders(), responseType: responseType } )

            return await getPayload(response,responseType)

        } catch (e) {
            console.log(e)
            throw e
        }

    },    

    post: async (type, data, responseType, pathParam) => {

        if (!type || !responseType) {
            throw new Error("post parameters incomplete")
        }

        try {
            let url = buildUrl(type, pathParam)

            let response = await axios.post(url, data, {headers: buildHeaders(), responseType: responseType} )

            return await getPayload(response, responseType)

        } catch (e) {
            console.log(e)
            throw e
        }
    },

    postJson: async (type, data, pathParam) => {
        try {
            return await idcApi.post(type, data, 'json', pathParam)
        } catch (e) { throw e; }
    }
    
}

export default idcApi;