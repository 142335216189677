import React from 'react'
import StyledModal, {StyledH2, StyledP} from '../styled/modal'
import styled from 'styled-components'
import locked from '../../images/locked.svg'
import unlocked from '../../images/unlocked.svg'

const LockedImg = styled.img`
    margin: auto 15px auto 0;
    border-radius: 10px;
    background-color: inherit;
    outline: none;
    height: 40px;
    width: 40px;
    z-index: 5;
    float: left;
    @media (max-width: 768px) {
        height: 40px;
        width: 40px;      
    }
    @media (max-width: 414px) {
        height: 40px;
        width: 40px;
    }
    `;

export default function one (e) {

    const dems = {
        height: '295px',
        left: '50%',
        top: '5%',
        dangerousCSS: 'transform: translate(-50%);'
    }
    return <>
    <StyledModal {...dems}>
        <StyledH2>Chop It Up!</StyledH2>
        <StyledP>Choose ICM or Chip Chop.</StyledP>
        <StyledP><LockedImg
            src={locked}/>
        Lock to enter total chips first and assist in individual counts.
        <b> If locked, work down.</b></StyledP>
        <StyledP><LockedImg
            src={unlocked}/>
        Unlock to enter individual chip counts first and calculate total chips.</StyledP>

        {e}
    </StyledModal>
    
    </>

}