import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';


const BoardWrapper = styled.div`
    border-radius: 40%;
    border: 5px #5B3C1F solid;
    padding: 10px;
    background: #255B1F;
`;

const BoardFieldWrapper = (props) => {

    return (
        <BoardWrapper>
            {props.children}
        </BoardWrapper>
    )
}

export default BoardFieldWrapper;