import React, { createContext, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

const GroupWrapper = styled.div`
    display: flex;
    flex-direction: row;
  `;

const SelectButton = styled.button`
    flex: 1 1 auto;
    margin: 5px 10%;
    border-radius: 15px;
    border: 3px solid ${props => props.value ? props.theme.toggleButtonTextSelected : props.theme.inputBorder};
    padding: 8px 15px;
    flex-basis: 2;
    background-color: ${props => props.value ? props.theme.toggleButtonOnBackground : props.theme.toggleButtonBackground };
    
    outline: none;

    color: ${props => props.value ? props.theme.toggleButtonTextSelected : props.theme.textColor};

    font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
    font-size: 1.1em;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    line-height: 20px;
    -webkit-text-shadow: 3px 3px 4px -1px rgba(0,0,0,0.77); 
    text-shadow: 1px 1px rgba(0,0,0,0.1);

    @media (max-width: 768px) {
        font-size: 0.9em;
    }

    @media (max-width: 411px) {
        font-size: 0.8em;
        white-space: nowrap;
        overflow: hidden;
    }
    

  `;

const Tg = (props) => {

    return (
        <GroupWrapper>
            <SelectButton value={props.value} onClick={ () => props.update(true) } >
                ICM Chop
            </SelectButton>
            <SelectButton value={!props.value} onClick={ () => props.update(false) } >
                Chip Chop
            </SelectButton>

        </GroupWrapper>
    )

}

Tg.propTypes = {
    value: PropTypes.bool.isRequired
}

export default Tg;