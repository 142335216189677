import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

//border: #aab4b5 solid 3px;
const StyledEquityDiv = styled.div`
    max-width: 40%;
    background: rgba(255,255,252,0.7);
    border-radius: 10px 10px 0px 10px;
    padding: 10px;
    border: solid 2px ${props => props.theme.inputBorder};
    color: #BA1937;
    font-weight: bold;
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 99;
    margin: 0 auto;
    text-align: center;

    p {
        margin: 0;
    }

    @media (max-width: 414px) {
        padding: 5px;
    }
`;
/**
 * A small box to the right of each hand that contains a passed result percentage.
 * May be empty but needs to be absolute spacing
 * 
 * Possibly passed entire result set so color can be modified appropriately.
 * 
 * @param props 
 */
const EquityResult = (props) => {

    const [equityString, setEquityString] = useState("");

    
    const formatPercentage = () => {
        let winsOrTies = (props.wins + props.ties) === 0 ? 0 :
             (props.wins + props.ties) / props.numgames
        return "" + ( winsOrTies * 100)
            .toFixed(2) + "%";
    }

    useEffect ( () => {
        // update the state of the equity here
            setEquityString( formatPercentage() )
    });

    return (
        <>
        {equityString &&
            <StyledEquityDiv>
                 <p>{equityString}</p>
            </StyledEquityDiv>}
        </>
    )

}

export default EquityResult;