import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

const StyledResultDiv = styled.div`
    border: 2px solid ${props => props.theme.inputBorder};
    border-radius: 5px;
    background-color: ${props => props.theme.resultBackground};
    padding: 0 8px 0 2px;
    margin: ${props => props.showSlider ? `2px auto 39px auto` : `2px auto 4px auto`};
    display: flex;

    color: ${props => props.theme.textColor};

    text-align: center;
    font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
    font-size: 1.1em;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    line-height: 0px;
    -webkit-text-shadow: 3px 3px 4px -1px rgba(0,0,0,0.77); 
    text-shadow: 1px 1px rgba(0,0,0,0.1);
    height: 50px;
    max-width: 140px;

    @media (max-width: 768px) {
        margin: ${props => props.showSlider ? `2px auto 20px auto` : `2px auto 4px auto`} ;
        height: ${props => props.showSlider ? `50px` : `33px`};
    }

    @media (max-width: 451px) {
        max-width: 110px;
    }
  `;

const StyledH3 = styled.h3`
    padding: 0;
    margin: 8px 5px 0 0;
    text-align: top;
    display: block;
    font-size: 0.8em;

    @media (max-width: 414px) {
        font-size: 0.6em;
    }
`;

const StyledP = styled.p`
    margin: auto;
    padding: 0 25px;
    font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
    font-size: ${props => props.content && props.content.length > 7 ? '0.8em' : '1.1em'};
    text-align: center;

    @media (max-width: 450px) {
        padding: 0 5px;
        width: auto;
    }

`;

const ChopResult = (props) => {

    const [placeText, setPlaceText] = useState("")

    useEffect( () => {
        let pl = ['st', 'nd', 'rd', 'th']
        setPlaceText( () => props.place + 1 + (props.place > 3 ? pl[3] : pl[props.place]))
    }, [props])

    return (            
        <StyledResultDiv showSlider={props.showSlider}>
        <StyledH3>{placeText}</StyledH3>
        <StyledP  showSlider={props.showSlider} content={!!props.value && props.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }>
            {!!props.value && props.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }
        </StyledP> 
        </StyledResultDiv>

    );
}

ChopResult.propTypes = {
    value: PropTypes.number.isRequired,
    place: PropTypes.number.isRequired
}

export default ChopResult;