import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';


const ButtonWrapper = styled.div`
    padding: 0px;
    margin: 10px auto;
    width: 90%;
    display: flex;
    flow-direction: row;
`;

const GoWrapper = (props) => {

    return (
        <ButtonWrapper>
            {props.children}
        </ButtonWrapper>
    )
}

export default GoWrapper;