import React, { createContext, useState, useEffect } from 'react';
import idcApi from '../lib/idcApi'
import styled from 'styled-components';

const ModalOuter = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    align-items: center;
    justify-content: center;
    z-index: 2000;
    margin: auto;
    background: rgb(255,255,255,.8);
    text-overflow: "-";
`;

const Modal = styled.div`
    position: fixed;
    display: block;
    z-index: 2000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 80%;
    max-height: 80%;
    background: white;
    border-radius: 5%;
    border: 1px solid black;
    overflow: auto;
`;

const StyledP = styled.p`
    display: block;
    background: white;
    `;

const ButtonWrapper = styled.div`
    display = flex;
    `;

const StyledButton = styled.button`
    margin: 10px 10px;
    background: #FFFFFC;
    border-radius: 10px;
    padding: 25px;

    `;

const Em = (props) => {

    const sendReport = () => {

        /*let payload = props.error.map( (e) => {
            JSON.stringify(e)
        })*/

        let payload = {}

        Object.keys(props.error).forEach ( (e,i) => {
            if (!!props.error[e]) {
                payload[e] = props.error[e].toString()
            }
        })

        console.log(props.error)
        console.log(payload)

        idcApi.postJson('errorReport', payload)
            .then ( (response) => {
                console.log("Submitted Error Report", response)
                props.clearError()
            })
            .catch( e => {
                console.log("failed to submit report", e)
                props.clearError()
            })

    }

    const isNetworkError = () => Object.keys(props.error).includes('isAxiosError')

    console.log(props)
    return (
        <ModalOuter onClick={ () => {console.log("click"); props.clearError()} }>
            <Modal onClick={(e) => {e.stopPropagation();} }>
                <h1>Beta Error Report</h1>
                { /* If network error, let user know */
                Object.keys(props.error).includes('isAxiosError') && <><h2>Network Error</h2>
                <p><b>Network Error: </b>Error Report may also fail.</p>
                <p>Are you logged in? Possible <b>guest usage limit</b> exceeded.</p></>}
                {Object.keys(props.error).map ( (e,i) => {
                    if ( !!props.error[e] ) {
                        console.log(e, ":", props.error[e], Object.keys(props.error[e]) );
                    }
                    return (
                        <StyledP key={i}>
                            <b>{e.toString()}</b>
                             {!!props.error[e] ? (Object.keys(props.error[e]).length !== 0 ? JSON.stringify(props.error[e]) : `${props.error[e].toString()}` ): 'undefined'}
                        </StyledP>);
                })}
            <ButtonWrapper>
                <StyledButton onClick={ () => sendReport() }> Send to Devs! (in development)</StyledButton>
                <StyledButton onClick={ () => props.clearError() }> Nah, Clear </StyledButton>
            </ButtonWrapper>
            </Modal>
        </ModalOuter>
    )

}

export default Em;
