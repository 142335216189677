

export default (permutations = [], stop = false) => {

    
    function calcICM (chipCounts, payouts) {

        if (!chipCounts || !payouts) {
            console.log("Worker didn't get proper objects")
            return;
        }

        stop = false; // short circuit, on message could set to true

        let chipTotal = chipCounts.reduce ( (sum, a) => sum + a, 0 )

        return chipCounts.map( (e,i) => {
            if(stop) return null

            // array of chip counts of other players minus this one
            let chips = chipCounts.slice(0,i).concat(chipCounts.slice(i+1,chipCounts.length))

           
            // calculate equity for each prize
            return payouts.map (  (prize,pIndex) => {
                
                console.log("running: ", prize)
                // just need to calc each scenario of chip stacks above
                // with permutations, calculate different ways of placing
                if ( pIndex === 0) {
                    return ( e / chipTotal) * prize // first place
                } else {

                    if(stop) return null

                    // find permutations for each place, then calc probability of event happening
                    // chip stack / total chips 
                    // sum the probabilities and multiply by prize
                    
                    /*bad async code
                    return calcPermutations( chips, pIndex)
                        .then ( pResults => {
                            let rr =  Promise.all(pResults.map ( async (v,i) => {
                                if (stop) return null
                                let aboveSum = v.reduce( (a,b) => a+b);
                                let aboveProb = await calcProb(v,chipTotal)
                                return (aboveProb * ( e / (chipTotal - aboveSum) )) //* prize
                            })).then( r => r.reduce ((a,b) => a+b) * prize)
                            //console.log("rr",rr)
                            return rr
                        })*/
                    
                    // old non-async code
                    return calcPermutations( chips, pIndex).map( (v, i) => {
                        let aboveSum = v.reduce( (a,b) => a+b);
                        let aboveProb = calcProb(v,chipTotal)
                        return (aboveProb * ( e / (chipTotal - aboveSum) )) //* prize
                    }).reduce(  (a,b) =>  a+b ) * prize 
                }
                
                // sum EV of all prizes
            }).reduce(   (a,b) =>  a+b)

            // round the results
            // possible there ends up being a remainder? 
        }).map ( e => Math.round( e ))

    }

    function calcCombinations(set,length) {
        permutations=[]
        permute([],set,length)
        return permutations;
    }

    function calcPermutations(set,length) {
        permutations=[]
        permute([],set,length,false) // side effect
        return permutations
    }

    function calcProb(finish, chips, acc = 1) {
        if(finish.length === 0) return acc
        let c = finish[0]
        //let newfinish = [...finish] // did not transpile properly
        let newfinish = []
        Array.prototype.push.apply(newfinish,finish)
        newfinish.splice(0,1)
        return calcProb(newfinish, chips-c, acc * (c/chips))

    }

    function permute(partial, set, k, combo=true) {
        //if(set.length < k) return
        
        
        for (let i = 0; i < set.length; i++) {
            if(stop) return null

            if (k === 1) {
                permutations.push(partial.concat([set[i]]))
            }
            else {
                let copy = set.slice() // remove item at index and make copy
                if(combo) { copy.splice(0,i+1)}
                else {copy.splice(i,1)}
                //if (partial[0] === 1500) console.log(partial, set[i], set, k, set.slice().splice(0,1), i)
                permute(partial.concat(set[i]), copy, k - 1, combo) // recurse
            }

        }
    }
    
    /**
     * More work needed. Figure out how to get calcICM to return the promise array immediately.
     * Need to get onmessage to return out.
     * 
     * Probably have to go all the way through to permute and calcProb with async
     */
    onmessage =  (e) => {

        if (e) {
            console.log(e)

            if (!e.data['chips']) {
                console.log("caught sigterm")
                stop = true   // got no data, so stop any running process
            }
            
            stop = false
            e.data['payouts'].sort( (a,b) => b-a)
            /*calcICM(e.data['chips'],e.data['payouts']).then( results => {
                console.log("Promise all", results)
                Promise.all(results).then( result => {
                    if(!result) {
                        console.log("Result not received!");
                    } else {
                        console.log(result)
                        if(!stop) postMessage(result)
                    }
                })
            })*/
            postMessage(calcICM(e.data['chips'],e.data['payouts']))
           

        } else {
            console.log("Worker didn't receive message object")
        }
        
    }



    
}