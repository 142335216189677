import React, { useRef, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import ToolTips from './tooltip/tooltip';
import HandField from './components/HandField';
import HandFieldWrapper from './components/HandFieldWrapper';
import EquityResult from './components/EquityResult';
import BoardFieldWrapper from './components/BoardFieldWrapper';
import BoardField from './components/BoardField';
import GoWrapper from './components/GoWrapper';
import GoButton from './components/GoButton';
import ResultTextWrapper from './components/ResultTextWrapper';
import HandKeyBoard from './components/HandKeyBoard';
import idcApi from './lib/idcApi';
import HandRanges from './obj/HandRanges';
import hs from 'holdem-strings';
import PlayerText from './styled-components/H3Float'
import MessageModal from './components/MessageModal'
import Pockets from './images/pockets.svg'
import Hero from './images/hero.svg'
import Villian from './images/villian.svg'

const keyFrameFadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const AnalyzerDiv = styled.div`
    background: rgb(94,61,34);
    background: linear-gradient(0deg, rgba(94,61,34,0.17690826330532217) 0%, rgba(141,118,99,0.06766456582633051) 15%, rgba(201,198,198,0.45702030812324934) 51%, rgba(150,128,110,0.0760679271708683) 83%, rgba(94,61,34,0.09567577030812324) 100%);
    height: auto;
    min-height: 100vh;
    width: 100%;
    padding: 0px;
    border-radius: 0 0 5px 5px;
    animation: ${keyFrameFadeIn} 1s ease-in-out;    
    border-top: 1px solid #707070;
`;

const FlexDiv = styled.div`
    display: flex;
    justify-content: center;
    `;
const SplitDiv = styled.div`
    flex: 1 1 auto;
    margin: 2px;
    `;
const ColumnDiv = styled.div`
    display: flex;
    flex-flow: column;
    height: 100%;
    `;

const AddPlayerButton = styled.button`
    display: flex;
    align-self: stretch;
    width: 50px;
    font-size: 1.2em;
    justify-content: center;
    background-color: white;
    border-radius: 5px;
    margin: 5px;
    border-color: #5E3D22;

    @media (max-width: 768px) {
        font-size: 4vw;
    }
`

const UserErrors = ["400 BAD_REQUEST"]


function Ha(props) {

    // Hands is a string array, array location corresponds to hand #
    const [hands, setHands] = useState(["","","","","",""]);
    const [numHands, setNumHands] = useState(2);
    const [activeHand, setActiveHand] = useState(0)
    const [board, setBoard] = useState("");
    const [result, setResult] = useState({});
    const [resultText, setResultText] = useState("");
    const [toggleKeyboard, setToggleKeyboard] = useState(false)
    const [awaitingResult, setAwaitingResult] = useState(false)

    // Message states
    const [messageModal, setMessageModal] = useState("")
    const [messageVersion, setMessageVersion] = useState(0)


    // Selected form by user. Determines if keyboard is shown and what field keyboard controls
    const [selected, setSelected] = useState("null");

    // Refs to hand fields which are dynamically created
    // Used for refocusing hand field
    const handFieldRefs = useRef(null)


    /**
     * Updates state when hand field modified.
     * @param {1-6} modHandNum 
     * @param {*} e 
     */
    const onHandChange = (modHandNum, e) => {
        
        setHands ( prevState => {

            let newState = [...prevState]
            newState[modHandNum] = e
            return newState
        })

        setResult({})
    }

    const onBoardChange = (e) => {
        setBoard (e.target.value)
        setResult({})
    }

    /**
     * Receives a String in format: +Ac -Ac and modifies board
     * 
     * @param {String} newcard 
     */
    const addToBoard = (newcard) => {
        const regex = /[\+-][AKQJT987654321][cdhs]/gmi
        if (newcard.match(regex)) { // Make sure is formatted properly (if not do nothing, no error)

            let nCard = newcard.substring(1,3) // New Card
            // update state
            setBoard ( prevBoard => {

                let newBoard = "";
                if(newcard.charAt(0) === '+') {
                    if ( !prevBoard.includes(nCard) ) {
                        newBoard = prevBoard.concat(nCard)
                    }
                } else {
                    newBoard = prevBoard.replace(nCard,"")
                }
    
            return newBoard;
            })
        }
    }

    /**
     * Uses state to construct a payload to idc api.
     */
    const onGoClick = () => {
        let payload = {
            hands: cleanHands(hands),
            board: board,
            dead: ""
        }

        setAwaitingResult(true)
        idcApi.postJson('handEquity', payload)
            .then ( response => {
                setAwaitingResult(false)
                if ( !!response.data && response.data.numGames > 0) {
                    console.log(response.data)
                    setResult(response.data);
                    setResultText(resultTextFromResponse(response.data));
                } else {
                    
                    // If response has a key with in UserErrors, print a small message modal
                    if (Object.keys(response).some(e => UserErrors.includes(e))) {
                        let message = UserErrors
                            .filter(e => Object.keys(response).includes(e))
                            .map(e => response[e].message)
                            .join(",")                            
                        
                        setMessageModal(message)
                        setMessageVersion(messageVersion + 1)
                    } else {

                        // else, the error may not be user generated
                        props.appErrorHandler({"Error Post Response": "Malformed: no data",
                                            "object": response})
                    }
                }
                
            })
            .catch ( 
                
                /* @TODO: This will often usage limit exceeded */
                /* Need to clear up results from idc-portfolio and rewrite
                 * this to fully process the response */
                e => {
                    setAwaitingResult(false)
                    console.log(e.response.data)
                    props.appErrorHandler(e)
            })

    }

    const resultTextFromResponse = (data) => {

        let resultText = data.resultText; 

        return resultText;
    }

    /**
     * Prepares the hand array for payload
     * 
     * @param {array of strings} hands 
     */
    let cleanHands = (hands) => {
        return hands.filter( e => e !== "")
    }

    const updateHand = (handFromKeyboard) => {
        setHands ( prevState => {

            let newState = [...prevState]
            newState[activeHand] = hs.compressArray(handFromKeyboard).toString()
            return newState
        })

        setResult({}) // clear equities

        focusActiveHand();

    }

    const focusActiveHand = (v) => {

        !!handFieldRefs.current.children[v ? v : activeHand] && 
        handFieldRefs.current.children[v ? v : activeHand]
        .children[1].children[0].focus() // focus the current hand

    }

    const setActive = (i) => {
        setActiveHand(i)

    }

    useEffect ( () => {
        if ( selected === 'board') {
            setActiveHand(-1)
        } 
    }, [selected])

    /**
     * Clears all results. 
     * 
     * Used when the user changes the hand fields?
     * 
     */
    const clearResult = () => {
        setResultText("")
        setResult({})
    }

    useEffect( () => {

        // if numHands reduced, must update hands to force render
        if (hands.length >= numHands) {
            setHands( prev => {
                let newHands = [...prev]
                for(let i = 0; i < newHands.length; i++) {
                    if(i >= numHands) {
                        newHands[i] = ""
                    }
                }
                return newHands
            })
        }

    },[numHands])

    const keyboards = hands.map( (e,i) =>
        <HandKeyBoard key={i} 
            handRanges={HandRanges}
            hand={e}
            update={(hs) => updateHand(hs)}
            updateToggleKeyboard={ () => { setToggleKeyboard(!toggleKeyboard); focusActiveHand(); } }
            toggleKeyboard={toggleKeyboard} />)

    const boardKeyboard = <HandKeyBoard
            handRanges={null}
            hand={board}
            update={ (hs) => addToBoard(hs) }
            updateTaggleKeyboard={ () => {setToggleKeyboard(!toggleKeyboard); } }
            toggleKeyboard={toggleKeyboard} />

    return (
        <AnalyzerDiv>
           <ToolTips tipName={"range"}>
           <MessageModal messageVersion={messageVersion} message={messageModal} />
            <FlexDiv>
            <SplitDiv>
                <ColumnDiv ref={handFieldRefs} >
            {hands.map ( (h,i) => (
                (h !== "" || i < numHands) &&
                <HandFieldWrapper key={h+i}
                    selected={i===activeHand}
                    onClick={() => setActive(i)}
                     >  
                    <div></div> {/*no clue why i need this*/}
                    {/*i === 0 && <PlayerText>Hero</PlayerText>}
                    {i !== 0 && <PlayerText>{`Villain ` + i}</PlayerText>*/}                  
                    <div>
                    <img src={i === 0 ? Hero : Villian} style={{width: "30px"}} />
                        <HandField 
                            key={i}
                            onFocus={ () => setSelected(i.toString()) }
                            onChange={onHandChange} hand={h} num={i}
                            toggleKeyboard={toggleKeyboard}
                            />
                    </div>
                    

                    {typeof result['wins' + (i+1)] !== 'undefined' && <EquityResult wins={result['wins' + (i+1)]} ties={result['ties' + (i+1)]} numgames={result.numGames}/> }

                </HandFieldWrapper>))}
                <FlexDiv>
                    {numHands < 3 && <AddPlayerButton onClick={() => setNumHands( (p) => p+1)} >+</AddPlayerButton>}
                    {numHands > 2 && <AddPlayerButton onClick={() => setNumHands( (p) => p-1)} >-</AddPlayerButton>}
                </FlexDiv>
                </ColumnDiv>
            </SplitDiv>
            <SplitDiv>
            <ColumnDiv>
                <BoardFieldWrapper>
                    <BoardField value={board} onFocus={ () => { setSelected("board") } } onChange={onBoardChange} />
                </BoardFieldWrapper>
                <GoWrapper>
                    <GoButton awaitingResult={awaitingResult} onClick={onGoClick} />
                </GoWrapper>
                <ResultTextWrapper resultText={resultText}>
                </ResultTextWrapper>
            </ColumnDiv>
            </SplitDiv>

            </FlexDiv>


            { /* check selected, pass proper string */
            /*selected != "null" &&*/
            /* TODO: pass array of hands from field 
            !!hands[activeHand] ?
            <HandKeyBoard handRanges={HandRanges} hand={hands[activeHand]} update={(hs) => updateHand(hs)} /> :
            <HandKeyBoard handRanges={HandRanges} update={(hs) => updateHand(hs)} /> */}

            {activeHand !== -1 && keyboards[activeHand] } 
            {activeHand === -1 && boardKeyboard}
            
             
            </ToolTips>
        </AnalyzerDiv>
    );
}

export default Ha;