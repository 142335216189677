import React from 'react';
import styled from 'styled-components'

const PlayerText = styled.h3`

    color: ${props => props.theme.textColor};

    text-align: center;
    font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
    font-size: 1.1em;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    line-height: 0px;
    -webkit-text-shadow: 3px 3px 4px -1px rgba(0,0,0,0.77); 
    text-shadow: 1px 1px rgba(0,0,0,0.1);
    padding: 0;
    margin: 4px;
    margin-top: 8px;
    text-align: center;
    display: inline-block;
    float: right;
    font-size: 1.2em;

    @media (max-width: 414px) {
        font-size: 0.6em;
        margin-top: 4px;
    }
`;   

export default PlayerText;