import React, { useRef, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import locked from '../images/locked.svg'
import unlocked from '../images/unlocked.svg'
import {StyledSmallTotalField,StyledTotalField, FloatingButton, StyledDiv} from '../styled-components/LockButtonStyles'

const StyledRemainingDiv = styled.div`
  width: 100%;
  flex-basis: 100%;

  p {
      color: ${props => props.theme.textColor};
      font-size: 0.7em;
  }
`

const Pb = (props) => {

    const [value, setValue] = useState(() => props && !!props.value ? props.value : 0 )
    const [valueWithCommas, setValueWithCommas] = useState("")
    const [isFocused, setFocused] = useState(false)
    const [remainingValue, setRemainingValue] = useState(0)
    const [paidValue, setPaidValue] = useState(0)
    
    // ref for selecting text
    const inputRef = useRef(null)

    /**
     * Received new props
     */
    useEffect( ()  => {
        //console.log('useeffect', props.value, value)
        setValue(() => props && !!props.value ? props.value : value)
    },[props])

    /**
     * Value of field changes. Update value with commas
     */
    useEffect( () => {
        setValueWithCommas(value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
    },[value])

    /**
     * Focused changed. If selected, highlight all text
     */
    useEffect ( () => {

        if (isFocused) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [isFocused])

    const updateTotal = (newTotal) => {
        console.log(newTotal,isFocused, props.lockValue)

        if (newTotal > 9) {
            console.log("Updating: ", newTotal)
            props.update(newTotal)
        } else {
            setValue(newTotal)
        }
    }

    return (
        <StyledDiv onClick={() => setFocused(!isFocused)} isFocused={isFocused}>
            {isFocused && 
            <StyledTotalField 
                tabIndex={props.tabIndex}
                value={value}
                onKeyDown={props.onKeyDown}
                onBlur={() =>  setFocused(false) }
                inputMode={"numeric"}
                disabled={!props.lockValue ? "disabled" : ""}
                ref={inputRef}
                onChange={ (e) => updateTotal(e.target.value)} />}
            {!isFocused &&
            <StyledSmallTotalField 
                tabIndex={props.tabIndex}
                value={valueWithCommas}
                disabled={!props.lockValue ? "disabled" : ""}
                onFocus={() =>  {if(props.lockValue) setFocused(true);} }
                onChange={ (e) => updateTotal(e.target.value)} />}
            {!isFocused && <StyledRemainingDiv>
                <p>Remaining: {props.remainingPrizePool}</p>
            </StyledRemainingDiv>}

        </StyledDiv>
        )

}

Pb.propTypes = {
    value: PropTypes.number,
    update: PropTypes.func.isRequired,
    toggleLock: PropTypes.func.isRequired,
    lockValue: PropTypes.bool
    /*change: PropTypes.func.isRequired*/
}


export default Pb;