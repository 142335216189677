/**
 * Build an array of components which will be in the ./steps directory.
 * Which component is shown is based on localstorage, simple int that increments.
 * If the int is greater than the array size, nothing renders.
 * 
 * The localstorage variable name will be provided in props so this can be reuseable.
 * Each variable name will have a different array
 * 
 * Components can pass back next() or end()
 */
import React, { useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledContainer = styled.div`
    position: relative;
`;

const OkButton = styled.button`
    border-radius: 5px;
    border: #707070 solid 1px;
    background-color: #81918d;
    margin: 0 5px;
    padding: 15px 25px;
    box-shadow: 3px 3px 3px 0px #000000;
`;

const NextButton = styled.button`
    border-radius: 5px;
    border: #707070 solid 1px;
    background-color: #c4c0a3;
    margin: 0 5px;
    padding: 15px 25px;
    float: right;
    box-shadow: 3px 3px 3px 0px #000000;
`;

const ButtonHolder = styled.div`
    padding: 5px 15px;
    position: absolute;
    bottom: 0;
    right: 0;
    
`;

const ToolTips = (props) => {

    const [tips, setTips] = useState([])
    const [currentTip, setCurrentTip] = useState(0)
    const [CurrentTipModule, setCurrentTipModule] = useState({})

    const FINISHED = 1000   // Probably not used. Instead setting currentTip to length of tips
                            // will facilate adding additional tips later

    useEffect( () => {

        try {
            if ( window.localStorage.hasOwnProperty(props.tipName) ) {
                setCurrentTip(window.localStorage.getItem(props.tipName))
            } else {
                setCurrentTip(1)
                window.localStorage.setItem(props.tipName, 1)
            }
        } catch (e) {
            console.log("Unable to write local storage, no tool tips for you!")
            setCurrentTip(FINISHED)
        }

        if (!!props.tipName) {

            // Clear just in case this is a re render
            // Will this work if we need to keep the step though? It. should.
            setTips([])

            for(let i = 1; i < 20; i++) {
                try {
                    let n = require('./' + props.tipName + '/' + i)
                    setTips ( prevTips => {
                        return prevTips.concat([n])
                    })
                } catch (e) {
                    // couldn't find file, exit loop
                    i = 20
                }
            }
        }


    }, [])

    useEffect ( () => {

        if ( !!tips && currentTip > 0) {
            window.localStorage.setItem(props.tipName, currentTip)

        }
    }, [currentTip])


    const OnNext = (e) => {
        setCurrentTip ( prev => Number(prev)+1)

    }

    const OnOk = (e) => {
        if (!!tips) {
            setCurrentTip(tips.length+1)
        }
    }
    

    //console.log(tips, tips[0], currentTip)

    return (
        <StyledContainer>
            {/* {!!tips && tips.map ( (e) => {
                let Tip = e.default
                // temp, we would normally display based on localstorage

                return <Tip key={e.default.name}>
                        <ButtonHolder>
                        <OkButton>
                            Skip
                        </OkButton>
                        <NextButton onClick={OnNext}>
                            Next
                        </NextButton>
                        </ButtonHolder>
                    </Tip>
            })} */}
            {(!!tips && currentTip > 0 && currentTip < tips.length+1 && tips.length > 0) && 
                tips[currentTip-1].default( 
                    <ButtonHolder>
                    <OkButton onClick={OnOk}>
                        Skip
                    </OkButton>
                    <NextButton onClick={OnNext}>
                        Next
                    </NextButton>
                    </ButtonHolder>
            )}
            {props.children}
        </StyledContainer>
    )

}

export default ToolTips;